import React from 'react'
import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

function AuthLayout() {
    const auth = localStorage.getItem("token") ? true : false;
    return auth ? <Navigate to="/first-aid-kits/all-kit" /> :
        <div className="thales-view-auth">
            <Outlet />
        </div>
}
export default AuthLayout;