import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Routes, Route, Navigate} from 'react-router-dom';
import { useProfileDetails } from "hooks/profile/profile";
import './App.css';

//layouts
import AuthLayout from './layouts/authLayout';
import AppLayout from './layouts/appLayout';

// Auth Routes
const SignIn = lazy(() => import("./views/auth/signIn"));

//App Routes
const Allkit = lazy(() => import("./views/app/firstAidKits/allKit"));
const AuditReport = lazy(() => import("./views/app/firstAidKits/auditReport"));
const Medicine = lazy(() => import("./views/app/firstAidKits/medicine"));

const Profile = lazy(() => import("./views/app/profile"));
const Admin = lazy(() => import("./views/app/profile/admin"));
const Roles = lazy(() => import("./views/app/profile/roles"));
const Mail = lazy(() => import("./views/app/profile/mailIds"));

function App() {

  const { data: profileData, refetch: initialRefetch } = useProfileDetails();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (profileData) {
      setData(profileData?.data?.resDto)
    }
  }, [profileData])

  return (
    <Suspense >
      <Routes>

        <Route element={<AuthLayout />}>
          <Route exact path="/" element={<SignIn />} />
          <Route path="/login" element={<SignIn />} />
        </Route>

        <Route element={<AppLayout profileData={profileData} initialRefetch={initialRefetch} />}>
          <Route path="/" element={<Navigate to={"/first-aid-kits/all-kit"} />} />
          <Route path="/first-aid-kits" element={<Navigate to={"/first-aid-kits/all-kit"} />} />
          <Route path="/first-aid-kits/all-kit" element={<Allkit  />} />
          <Route path="/first-aid-kits/medicine" element={<Medicine  />} />
          <Route path="/first-aid-kits/audit-report" element={<AuditReport  />} />
          

          <Route path="/profile" element={<Profile initialRefetch={initialRefetch} profileData={profileData} />} />
          <Route path="/admin" element={<Admin hide={data} />} />
          <Route path="/roles" element={<Roles />} />
          <Route path="/mail-ids" element={<Mail />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
