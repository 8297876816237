import React, { useEffect, useState } from 'react';
import FirstAidKitHeader from 'views/app/firstAidKits/allKit/common/header';
import { profile_image_type} from 'helpers/constants/constants';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { Row, Col, Avatar, Popover } from 'antd';
import PrivateImage from 'helpers/PrivateImage';
import globalRouter from "globalRouter.ts";
import Image from 'helpers/Image';
import "../utils/style.css"



function AppLayout({ profileData, initialRefetch }) {

    let navigate = useNavigate()
    const auth = localStorage.getItem("token") ? true : false;

    console.log(auth);

    const [logout, setLogout] = useState(false);

    // const timeout = 1800000; // 30 minutes in milliseconds
    const timeout = 3600000; // 1 hour in milliseconds

    const onlogout = () => {
        localStorage.removeItem('token');
        navigate("/login");
    }

    const content = (
        <div className='pop-hover-content'>
            <div className='pop-profile' onClick={() => navigate("/profile")}>
                <p>Profile</p>
                <Image name="profile-right-arrow.svg" />
            </div>
            <hr />
            <div className='pop-logout' onClick={onlogout}>
                <p>Logout</p>
            </div>
        </div>
    );


    useEffect(() => {
        let timer;

        const resetTimer = () => {
            clearTimeout(timer);
            timer = setTimeout(() => setLogout(true), timeout);
        };

        const events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];

        const resetEvents = () => {
            events.forEach(event => {
                window.addEventListener(event, resetTimer);
            });
        };

        resetTimer();
        resetEvents();

        return () => {
            clearTimeout(timer);
            events.forEach(event => {
                window.removeEventListener(event, resetTimer);
            });
        };
    }, [timeout]);

    useEffect(() => {
        if (logout) {
            // Perform logout actions here, e.g., redirect to login page
            localStorage.removeItem('token');
            globalRouter.navigate("/login", { state: { message: "Session Time Out! Please ReLogin." } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logout]);

    useEffect(() => {
        initialRefetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return auth ?
        <div className="thales-app-view">
            <div className="thales-body">
                <div className='thales-app-view-profile'>
                    <Image name="login_logo.svg" className="sidebar-logo" />
                    <FirstAidKitHeader />
                     <div className='download-apps-profile-container'>
                     <div className='download-apps'>
                                        <a href="https://play.google.com/store/apps/details?id=com.corpfield.thales_first_aid_box" rel="noreferrer" target='_blank' >
                                            <Image name="google-play-icon.svg" className="playstore_icon" />
                                        </a>
                                        <a href="https://apps.apple.com/us/app/thales-fab/id6502820891" rel="noreferrer" target='_blank' >
                                            <Image name="app-store-icon.svg" className="appstore_icon" />
                                        </a>
                                    </div>
                    <Popover content={content} placement='bottom'>
                    <div className='profile'>
                        <Row gutter={[16, 8]}>
                            <Col xs={6} md={6}>
                                {profileData?.data?.profileImageUrl ? <PrivateImage className="sidebar-pic" privateUrl={profileData?.data?.profileImageUrl} imgType={profile_image_type} /> : <Avatar size={35}>{profileData?.data?.firstName.charAt(0).toUpperCase()}</Avatar>}
                            </Col>
                            <Col xs={14} md={14}>
                                <p><b>{profileData?.data?.firstName} {profileData?.data?.lastName}</b></p>
                                <p>{profileData?.data?.roleName}</p>
                            </Col>
                            <Col xs={1} md={1}>
                                <Image className="dote" name="dote.svg" />
                            </Col>
                        </Row>
                    </div>
                    </Popover>
                     </div>
                </div>
                <Outlet />
            </div>
        </div> : <Navigate to="/" replace={true} />
}
export default AppLayout;