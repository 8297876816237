import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Image from 'helpers/Image';
import "./style.css"

const FirstAidKitHeader = () => {
    return (
        <Fragment>
            <header >
                <div className='header-top'>
                    <div className='first-aid-kit'>
                        < NavLink to="/first-aid-kits/all-kit"><Image name="config-employee.svg" />All Kits</NavLink>
                        < NavLink to="/first-aid-kits/Medicine"><Image name="config-employee.svg" />Medicine</NavLink>
                        <NavLink to="/first-aid-kits/audit-report" ><Image name="config-department.svg" />Audit Report</NavLink>
                    </div>
                </div>
            </header>
        </Fragment >
    )
}

export default FirstAidKitHeader;

